.editPageContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #F8FFF3;
}

.editContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2vh 4vw;
}

.previewContainer {
  width: 40vw;
  flex-shrink: 0;
  border-left:1px solid #808E86;
}

/* 

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}


.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}


.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
*/


.smartphone {
  position: relative;
  width: 20vw;
  height: 36vw;
  margin: auto;
  border: 1vw black solid;
  border-top-width: 2vw;
  border-bottom-width: 2vw;
  border-radius: 1vw;
}

.smartphone:before {
  content: '';
  display: block;
  width: 5em;
  height: 0.4em;
  position: absolute;
  top: -2.5em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 0.8em;
}

.smartphone:after {
  content: '';
  display: block;
  width: 3em;
  height: 3em;
  position: absolute;
  left: 50%;
  bottom: -5.4em;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}


.smartphone .content {
  width: 30em;
  height: 54em;
  background: white;
}