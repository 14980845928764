.addLinkContainer {
  display: flex;
  background-color: #F8FFF3;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.addLinkButton {
  width: 80%;
  margin: 2vh 2vw;
}