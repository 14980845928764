.container {
  background-color: #F8FFF3;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
}

.content {
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: space-around;
  flex-direction: column;
}

.profile {
  margin: 12vh 10vw 2vh 10vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.avatarContainer {
  transform: translateY(50%);
  height: 8em;
  width: 8em;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  width: 100%;
}

.name {
  padding: 10px;
}

.bio {
  color: #808E86;
  font-size: 0.8em;
  max-height: 10vh;
  overflow: scroll;
}

.linkList {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 2vh 2vw 10vh 2vw;
}

.background {
  height: 20%;
  /* width: 100%; */
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.linkCard {
  display: flex;
  justify-content: space-between;
  margin-left: 5vw;
  margin-bottom: 2vh;
  align-items: center;
  text-decoration: none;
  color: inherit;
  box-shadow: 0.2em 0.2em 0.4em 0.2em #DAE3DB;
  padding: 1vh 2vw 1vh 0;
  background-color: #FBFFFB;
}

.linkLogoContainer {
  transform: translateX(-1.2em);
  height: 2.4em;
  width: 2.4em;
  background: #DAE3DB;
  border-radius: 6%;
  /* padding: 2vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 1.8em;
  width: 1.8em;
  color: #263932;
}

.linkContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  flex-grow: 1;
  transform: translateX(-0.8em);
}

.linkDomain {
  color: #808E86;
  font-size: 0.8em;
}

.arrowIcon {
  color: #808E86;
  width: 1.5em;
  height: 1.5em;
}

.commonSocialMedias {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonSocialMediaIcon {
  height: 1.6em;
  width: 1.6em;
  margin-left: 1vw;
  margin-right: 1vw;
  color: #263932;
}