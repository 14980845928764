.editableItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 8vh;
  border: 1px dashed #263932;
  border-radius: 0.8em;
  margin: 2vh 0;
}

.draggableIconContainer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.draggableIcon {
  width: 3.2em;
  color: #808E86;
}

.linkInformationContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.toggleDisplay {
  flex-shrink: 0;
  width: 4em;
}

.linkName {
  width: 100%;
  display: flex;
}

.linkDomain {
  width: 100%;
  display: flex;
}

.trashButton {
  border: none;
  background: none;
}

.toggleButton {
  transform: scale(0.8);
  margin-bottom: 0.2em;
}
